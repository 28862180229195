.w-max-100px {
  max-width: 500px !important;
}

.w-min-100px {
  min-width: 300px !important;
}

.h-70px {
  height: 70px !important;
}

.text-title {
  color: #667a85;
}

.invalid-feedback {
  position: absolute;
  margin-top: 0.2rem !important;
}

.form-label {
  margin-bottom: 0.2rem !important;
}

.offline-container {
  filter: grayscale(100%);
}

#offline {
  filter: grayscale(100%);
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

#offline-contrainer {
  width: 100%;
  height: 100%;
}

// .table-wrapper {
//   max-height: 100px;
//   overflow: auto;
//   display: inline-block;
// }

.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}

.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #abb6c4;
}

.drag-border {
  border-radius: var(--ct-border-radius) !important;
  --ct-border-width: 2px;
  border: var(--ct-border-width) var(--ct-border-style) #a9afbd !important;
}

.form-control:disabled {
  background-color: hsl(0, 0%, 95%) !important;
  opacity: 1;
}

// .Select-menu-outer {
//   z-index: 9999;
//   position:relative;
// }

.list-group-item.active {
  z-index: unset !important;
}